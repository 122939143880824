import { OrgSelectorTplBuilder } from '@Components/orgSelector/orgSelectorInfoPanelTpl';

export class EdiwebOrgSelectorTplBuilder extends OrgSelectorTplBuilder {
	getMainBlockTpl(selectorInstance) {
		const me = this;
		const arraySections = super.getMainBlockTpl(...arguments);
		const personSection =
			`<tpl elseif="$v.type === 'person'">` + me.getMainBlockPersonTpl(selectorInstance).join(' ');
		arraySections.length = arraySections.length - 1;
		arraySections.push(personSection);
		arraySections.push('</tpl>');
		arraySections.push(...me.getMainBlockPersonInnTpl());
		return arraySections;
	}

	getMainBlockPersonTpl() {
		return [
			'<tpl if="!Ext.isEmpty($v.person_lastname || $v.person_firstname || $v.person_patronymicname)">',
			'<div class="edi-company-name">',
			'	<span class="row-data edi-company-person-lastname">{person_lastname}</span>',
			'	<span class="row-data edi-company-person-firstname"> {person_firstname}</span>',
			'	<span class="row-data edi-company-person-patronymicname"> {person_patronymicname}</span>',
			'</div>',
			'</tpl>'
		];
	}

	getMainBlockPersonInnTpl() {
		return this.createCodeValuesRow([
			{
				title: 'company.inn.short',
				name: 'person_inn',
				valueCls: 'edi-company-person-inn'
			}
		]);
	}
}

import { createModulePanel } from '@Components/panels';
import { createLabel } from '@Components/fields';
import {
	createActionsColumnConfig,
	createGrid,
	createGridActionBar,
	createGridCheckboxSelectionModel
} from '@Components/grid';
import { createModuleFilterForm } from '@Components/moduleFilterForm';
import { getUserFileStorageColumns } from './columns';

Ext.namespace('edi.modules');
/**
 * User file storage handling module
 * @author Pavel Pirogov
 */
edi.modules['user.file.storage'] = function () {
	var filterObject, moduleData, filterForm, grid, documentTypes, contentTypes, fireSearch;

	/**
	 * Module initialization
	 * @param {Object} data                Data from modules handler
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.fileStorage.on('change', gridDataChangedHandler);
	};
	/**
	 * Renders page layout
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});

		var continueLoading = function () {
			grid = createGridCmp();
			filterForm = createFilterForm();
			modulePanel.add(filterForm);

			modulePanel.add(grid);
			moduleData.tab.add(modulePanel);

			if (typeof initCallBack === 'function') {
				initCallBack();
			}
			fireSearch();
		};
		documentTypes = [];
		contentTypes = [];
		edi.rest.sendRequest(
			edi.rest.services.FILE_STORAGE.TYPES.GET,
			'GET',
			null,
			function (responseData) {
				var items = responseData.items;
				for (var i = 0; i < items.length; i++) {
					documentTypes.push({
						id: items[i],
						name: items[i]
					});
				}
			},
			null,
			function () {
				edi.rest.sendRequest(
					edi.rest.services.FILE_STORAGE.CONTENT_TYPES.GET,
					'GET',
					null,
					function (responseData) {
						var items = responseData.items;
						for (var i = 0; i < items.length; i++) {
							contentTypes.push({
								id: items[i],
								name: items[i]
							});
						}
					},
					null,
					continueLoading
				);
			}
		);
	};
	/**
	 * Creates filter form
	 * @returns {Object}
	 */
	var createFilterForm = function () {
		fireSearch = function () {
			if (!filterObject) {
				filterObject = edi.filters.createGridFilter(
					edi.rest.services.FILE_STORAGE.FILTER.GET,
					filterForm,
					grid,
					function (values) {
						values = edi.filters.config.generic.convertFieldName(values, 'docName');
						return values;
					},
					null,
					{
						persistence: {
							enabled: true,
							name: `${moduleData.modName}_packets`
						}
					}
				);
			}
			filterObject.filter();
		};
		let { formItemsMap, items } = edi.filters.config.fileStorage.createFormItems(
			documentTypes,
			undefined,
			contentTypes
		);
		return createModuleFilterForm(
			{
				border: 1,
				bodyPadding: '0 0 10 0',
				usePlaceholderWrapper: true,
				formItemsMap,
				items
			},
			fireSearch
		);
	};
	/**
	 * Initializes grid
	 * @returns {Object} Instance of grid
	 */
	var createGridCmp = function () {
		var columns = getUserFileStorageColumns(),
			actions = createActionColumn();
		if (actions !== null) {
			columns.push(actions);
		}

		var groupActionsItems = [];
		groupActionsItems.push({
			id: edi.constants.DOCUMENT_ACTIONS.DELETE,
			name: edi.i18n.getMessage('document.delete.document')
		});

		var topBar,
			totalsLabel = createLabel({
				text: edi.i18n.getMessage('records.selected.totals', {
					selectedRows: 0
				}),
				cls: 'edi-tbar-total-label-right'
			});

		if (groupActionsItems.length) {
			var groupActionsItemClick = function (id) {
				if (edi.constants.DOCUMENT_ACTIONS.DELETE === id) {
					edi.methods.fileStorage.remove(grid);
				}
			};
		}

		topBar = createGridActionBar({
			actionCfgs: groupActionsItems,
			defaultHandler: groupActionsItemClick
		});

		var grid = createGrid({
			saveSorters: true,
			savedSortersName: 'user_file_storage',
			proxyConfig: {
				type: 'ajax',
				url: null
			},
			storeConfig: {
				model: edi.models.getModel('FILE_STORAGE'),
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				autoLoad: false
			},
			gridConfig: {
				columns: columns,
				padding: 0,
				region: 'center',
				border: 1,
				listeners: {
					celldblclick: function (view, td, cellIndex, record) {
						edi.methods.fileStorage.details(record.getData());
					}
				},
				selModel:
					topBar && topBar.menuActionItemsCnt
						? createGridCheckboxSelectionModel({
								topBar: topBar,
								selectionProcessor: function (model, selected, actionItems) {
									edi.methods.gridSelModules(model, selected, actionItems, topBar, totalsLabel);
								}
						  })
						: undefined,
				tbar: topBar
			},
			pagingBarConfig: {
				totalsLabel: topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined
			}
		});
		return grid;
	};
	/**
	 * Creates action column of the grid
	 * @returns {Object} Config for action column
	 */
	var createActionColumn = function () {
		var items = [
			{
				glyph: edi.constants.ICONS.DETAILS,
				testCls: 'test-action-column-details',
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex);
					edi.methods.fileStorage.details(record.getData());
				}
			},
			{
				glyph: edi.constants.ICONS.EDIT,
				testCls: 'test-action-column-edit',
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex);
					edi.methods.fileStorage.createOrUpdate(record.getData());
				},
				isActionDisabled: function (view, rowIndex, colIndex, item, record) {
					var status = record.get('processed');
					return record.get('byUser').id != edi.core.getUserData().id || status === 'Y' || status === 'P';
				}
			}
		];
		return createActionsColumnConfig({
			items: items,
			width: edi.utils.getActionColumnWidth(items.length)
		});
	};
	/**
	 * Reloads grid and resets filter form
	 */
	var gridDataChangedHandler = function () {
		grid.getStore().reload();
	};
	/**
	 * Routine that must be done before module destroy
	 */
	var onDestroy = function () {
		if (filterObject && filterObject.searchTimeout) {
			clearTimeout(filterObject.searchTimeout);
		}
		edi.events.fileStorage.un('change', gridDataChangedHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
	};
};

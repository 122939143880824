// @ts-ignore
import { createButtonContainer, createContainer, createFieldBlock } from '@UIkit/components/panels';
import { createEmailField, createPhoneField, createTextField } from '@Components/fields';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
// @ts-ignore
import { createTab } from '@UIkit/components/tab';
import { createFormForModule } from '@Components/panels';
// @ts-ignore
import { FIELD_BLOCK_CLS } from '@UIkit/components/panels/FieldBlock/FieldBlock';
import { PersonalInfoTabProps } from '../definitions';
import { SendRequestResponse } from '@App/js/definitions/request';
import { utils } from '@App/js/utilities';

export class PersonalInfoTab {
	fields: {
		[fieldName: string]: ExtComponent;
	};
	isEditAllowed: boolean;
	props: PersonalInfoTabProps;
	form: ExtComponent;

	constructor(props: PersonalInfoTabProps, overrides?: Partial<PersonalInfoTab>) {
		const me = this;
		me.modifyConfig(props, overrides);
	}

	modifyConfig(props: PersonalInfoTabProps, overrides?: Partial<PersonalInfoTab>) {
		const me = this;
		me.fields = {};
		me.isEditAllowed =
			edi.permissions.hasPermission('EDIT_USER_PROFILE') &&
			edi.permissions.hasPermission('EDIT_EDI_USER_PROFILE');
		Ext.merge(me, overrides);
		me.props = props;
	}

	createFieldWrapper(config = {}) {
		return createFieldBlock(
			Object.assign(
				{
					cls: FIELD_BLOCK_CLS.small
				},
				config
			)
		);
	}

	createLastNameField() {
		const me = this;
		const lastName = (me.fields.lastName = createTextField({
			allowBlank: false,
			maxLength: 35,
			disabled: true,
			name: 'lastName',
			valueSrc: me.props.data
		}));
		return me.createFieldWrapper({
			title: edi.i18n.getMessage('user.profile.last.name'),
			cls: FIELD_BLOCK_CLS.small,
			items: lastName
		});
	}

	createFirstNameField() {
		const me = this;
		const firstName = (me.fields.firstName = createTextField({
			allowBlank: false,
			maxLength: 35,
			disabled: true,
			name: 'firstName',
			valueSrc: me.props.data
		}));
		return me.createFieldWrapper({
			title: edi.i18n.getMessage('user.profile.first.name'),
			items: firstName
		});
	}

	createPatronymicNameField() {
		const me = this;
		const patronymicName = (me.fields.patronymicName = createTextField({
			allowBlank: true,
			maxLength: 35,
			disabled: true,
			name: 'patronymicName',
			valueSrc: me.props.data
		}));
		return me.createFieldWrapper({
			title: edi.i18n.getMessage('user.profile.patronymic.name'),
			items: patronymicName
		});
	}

	createPhonesField() {
		const me = this;
		const phones = (me.fields.phones = createPhoneField({
			allowBlank: true,
			disabled: !me.isEditAllowed,
			name: 'phones',
			valueSrc: me.props.data
		}) as ExtComponent);
		return me.createFieldWrapper({
			title: edi.i18n.getMessage('user.profile.phone'),
			items: phones
		});
	}

	createAdditionalPhoneField() {
		const me = this;
		const additionalPhone = (me.fields.additionalPhone = createTextField({
			allowBlank: true,
			maxLength: 32,
			disabled: !me.isEditAllowed,
			name: 'additionalPhone',
			valueSrc: me.props.data
		}));
		return me.createFieldWrapper({
			title: edi.i18n.getMessage('additional.phone'),
			items: additionalPhone
		});
	}

	createPersonalIdField() {
		const me = this;
		if (me.props.data?.personalId) {
			me.props.data.personalId = edi.converters.SNILS.convertForShow(me.props.data.personalId);
		}
		const personalId = (me.fields.personalId = createTextField({
			readOnly: !!me.props.data?.personalId?.length,
			disabled: !me.isEditAllowed,
			allowBlank: true,
			maxLength: 14,
			regex: edi.constants.VALIDATORS_MULTI.RU.SNILS,
			regexText: edi.i18n.getMessage('invalid.snils.format'),
			inputMask: edi.constants.FIELD_MASK.SNILS,
			name: 'personalId', // в прибалтике нет СНИЛС, поэтому personalId
			itemId: 'personalId',
			valueSrc: me.props.data,
			tooltip: !!me.props.data?.personalId?.length ? edi.i18n.getMessage('profile.snils.tooltip') : null
		}));
		return me.createFieldWrapper({
			title: edi.i18n.getMessage('person.snils'),
			items: personalId
		});
	}

	createEmailField() {
		const me = this;
		const eMail = (me.fields.eMail = createEmailField({
			allowBlank: true,
			disabled: !me.isEditAllowed,
			name: 'eMail',
			valueSrc: me.props.data
		}) as ExtComponent);
		return me.createFieldWrapper({
			title: `${edi.i18n.getMessage('email')} ${
				me.props.data.emailConfirmed
					? "<span class='edi-data-good'>" + edi.i18n.getMessage('user.profile.email.confirmed') + '</span>'
					: "<span class='edi-data-error'>" +
					  edi.i18n.getMessage('user.profile.email.not.confirmed') +
					  '</span>'
			}`,
			items: eMail
		});
	}

	createNewEmailField() {
		const me = this;
		const newEmail = createTextField({
			allowBlank: false,
			maxLength: 35,
			disabled: true,
			value: me.props.isMD ? me.props.data.eMail : me.props.confirmationData?.email
		});
		const isAllow =
			!me.props.data.emailConfirmed && (me.props.isMD || (!me.props.isMD && me.props.confirmationData?.email));
		return isAllow
			? me.createFieldWrapper({
					title: edi.i18n.getMessage('user.profile.email.new'),
					items: newEmail
			  })
			: null;
	}

	createEmailConfirmButton() {
		const me = this;
		const isNeedToConfirm = (me.props.data.eMail && !me.props.data.emailConfirmed) || me.props.confirmationData;
		const emailConfirmBtn = isNeedToConfirm
			? createButton({
					cls: [BUTTON_CLS.outline, BUTTON_CLS.small],
					text: edi.i18n.getMessage('action.confirm.email'),
					padding: '26 0 0 0',
					handler: function () {
						me.props.moduleInstance.setLoading?.(true);
						edi.login.emailConfirmation(function (success: boolean) {
							me.props.moduleInstance.setLoading?.(false);
							success ? edi.core.showInfo('email.confirm.success') : null;
							edi.events.login.fireEvent('userChange');
						});
					}
			  })
			: null;
		return emailConfirmBtn;
	}

	createEmailRejectButton() {
		const me = this;
		if (me.props.confirmationData && me.props.data.eMail !== me.props.confirmationData.email) {
			return createButton({
				cls: [BUTTON_CLS.outline, BUTTON_CLS.small],
				text: edi.i18n.getMessage('email.reject.action'),
				padding: '26 0 0 0',
				handler: function () {
					me.props.moduleInstance.setLoading?.(true);
					const data = {
						[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT]: true,
						[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT_REASON]: ''
					};
					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.DOCUMENTS.SEND.PUT, {
							documentId: me.props.confirmationData!.id
						}),
						'PUT',
						Ext.encode(data),
						function () {
							edi.core.showInfo('email.reject.success');
						},
						function (data: SendRequestResponse) {
							edi.core.showError(edi.utils.formatComplexServerError(data, 'email.confirm.error'));
						},
						function () {
							me.props.moduleInstance.setLoading?.(false);
							edi.events.login.fireEvent('userChange');
						}
					);
				}
			});
		}
	}

	saveHandler() {}

	getForm() {
		const me = this;
		return me.form;
	}

	getValues() {
		const me = this;
		return edi.utils.collectFormValues(me.form, null, true);
	}

	createSaveButton() {
		const me = this;
		return createButton({
			text: edi.i18n.getMessage('form.btn.save'),
			handler: function () {
				const formIsValid = me.getForm().isValid();
				const invalidField = utils.findAndFocusInvalidField({ fields: Object.values(me.getFields()) });
				if (formIsValid && !invalidField) {
					me.saveHandler();
				}
			}
		});
	}

	getFields() {
		const me = this;
		return me.fields ?? {};
	}

	getFieldByName({ name }: { name: string }) {
		const me = this;

		if (!name) return;

		const allFields = me.getFields();
		return allFields[name] ?? Object.values(allFields).find((field) => field.name === name);
	}

	getFormCls(): string | string[] {
		return 'edi-details-panel';
	}

	createView() {
		const me = this;
		const lastName = me.createLastNameField();
		const firstName = me.createFirstNameField();
		const patronymicName = me.createPatronymicNameField();
		const phones = me.createPhonesField();
		const additionalPhone = me.createAdditionalPhoneField();
		const personalId = me.createPersonalIdField();
		const eMail = me.createEmailField();
		const newEmail = me.createNewEmailField();
		const emailConfirmBtn = me.createEmailConfirmButton();
		const emailRejectBtn = me.createEmailRejectButton();

		return (me.form = createFormForModule({
			cls: me.getFormCls(),
			layout: {
				type: 'grid',
				area: [[3, 1, 6]]
			},
			items: [
				createContainer({
					layout: {
						type: 'grid',
						gap: 24
					},
					items: [lastName, firstName, patronymicName]
				}),
				// Костыль для layout: grid, чтобы между полями была пустая колонка
				createContainer({
					hidden: true
				}),
				createContainer({
					layout: {
						type: 'grid',
						gap: 24,
						area: [4, 4, 4, emailRejectBtn ? [6, 3, 3] : [6, 3], 4]
					},
					items: [phones, additionalPhone, personalId, eMail, emailConfirmBtn, emailRejectBtn, newEmail]
				})
			],
			buttons: me.isEditAllowed
				? createButtonContainer({
						items: me.createSaveButton()
				  })
				: undefined
		}));
	}

	createTab() {
		const me = this;
		return createTab({
			title: edi.i18n.getMessage('user.profile.tab.personal.info'),
			panelType: 'tabpanel',
			closable: false,
			tabInstance: me,
			items: me.createView()
		});
	}
}

export const createPersonalInfoTab = function (
	props: PersonalInfoTabProps,
	overrides?: Partial<PersonalInfoTab>
): ExtComponent {
	const personalInfo = new PersonalInfoTab(props, overrides);
	return personalInfo.createTab() as ExtComponent;
};
